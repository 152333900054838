/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function configurer(){
   
      
    $(window).on('scroll', function() {
      scrollPosition = $(this).scrollTop();
      if (scrollPosition >= 150) {
          $('#site-header').addClass('fixed');
      }else {
        $('#site-header').removeClass('fixed');
      }
    });

    //ANCHOR

    $('.header-nav .nav-wrapper a').on('click touch', function(e) {

      var url = $(this).attr('href');

      if(url.indexOf("#") !== -1){    

        hash = url.split('#')[1];

        if($('#'+hash).length !== 0){

          $('html, body').animate({
            scrollTop: $('#'+hash).offset().top - 140
          }, 'slow');

          e.preventDefault();
          return false;

        }

      }
    });

    $('.forfaits-list .forfait a.btn').on('click touch', function() {

      $(this).parent().parent().parent().toggleClass('open');

      return false;

    });

    $('#bt-menu').on('click touch', function() {
      $('#site-header .header-nav').slideToggle();
      $('#site-header .header-nav').toggleClass('visible');
      $('body').toggleClass('no-scroll');
      $('#site-header').toggleClass('bg');
    });

    $('.slider-dots').slick({
      dots: true,
      arrows: true
    });

    $('.page-header-slider').slick({
      dots: true,
      arrows: false,
      autoplay: true,
      fade: true,
    });

    $('.slider-images').slick({
      dots: true,
      arrows: false
    });

    $('.slider-arrows').slick({
      dots: false,
      arrows: true
    });

    $('.video-slider').slick({
      arrows: true,
      dots: false,
      centerMode: true,
      centerPadding: '20%',
      useTransform: false,
      responsive: [
            {
              breakpoint: 768,
              settings: {
                centerPadding: '10%',
              }
            }
      ]
    });

    $('.charcoal-text-slider').slick({
      arrows: false,
      dots: false,
      fade: true,
      adaptiveHeight: true,
      asNavFor: '.histoire-slider',
    });

    $('.histoire-slider').slick({
      arrows: true,
      dots: true,
      fade: true,
      adaptiveHeight: true,
      asNavFor: '.charcoal-text-slider',
    });

    if($('#map-viewer').length > 0){

      
      $('#zoom .zoom').on('click touch', function() {

        if($('#map-container .image').attr('zoom-level')){
            var zoomLevel = $('#map-container .image').attr('zoom-level');
          }else {
            var zoomLevel = 0;
          }

        //ZOOM IN
        if($(this).hasClass('zoom-in')){

          if(zoomLevel < 20){

            zoomLevel = parseInt(zoomLevel)+1;

            $('#map-container .image').attr('zoom-level', zoomLevel);
            //$('#map-container .image').css('transform', 'scale(1.'+zoomLevel+')');
            
            var mapSize = (zoomLevel * 20 ) + 100;

            $('#map-container .image').css('height', mapSize+'%');

          }

        //ZOOM OUT
        }else {


          if(zoomLevel > 0){

            zoomLevel = parseInt(zoomLevel)-1;

            $('#map-container .image').attr('zoom-level', zoomLevel);
            
            var mapSize = (zoomLevel * 20 ) + 100;

            $('#map-container .image').css('width', mapSize+'%');
            //$('#map-container .image').css('transform', 'scale(1.'+zoomLevel+')');

          }

        }

        if(zoomLevel == 20){         
          $('#zoom .zoom.zoom-in').addClass('disable');
        }else {
          $('#zoom .zoom.zoom-in').removeClass('disable');
        }

        if(zoomLevel == 0){         
          $('#zoom .zoom.zoom-out').addClass('disable');
        }else {
          $('#zoom .zoom.zoom-out').removeClass('disable');
        }

      });

      $('.lightbox-toggle.map').on('click touch', function() {


        setTimeout(function() {
          var ele = $('#map-container');

          var halfHeight =  ele.find('.image').outerHeight();
          var halfWidth =  ele.find('.image').outerWidth();

          ele.scrollTop(halfHeight/2);
          ele.scrollLeft(halfWidth/2);

        }, 500);

      });

    }



    $('#lightbox-close').on('click touch', function() {
      $('.content-toggle').css('opacity', 0);

      setTimeout(function() {
        $('#lightbox').fadeOut();
        $('body').removeClass('no-scroll');
      }, 500);
    });

    $('#lightbox-close.chalet').on('click touch', function() {
      
      setTimeout(function() {

        $('.chalet-slider.visible').removeClass('visible');
        $('.chalet-slider-nav.visible').slick('unslick');
        $('.chalet-slider-nav.visible').removeClass('visible');

      }, 1000);
    });

    $('.lightbox-toggle').on('click touch', function() {

      $('body').addClass('no-scroll');

      $('#lightbox').fadeIn();
      var contentId = $(this).attr('data-content-id');

      setTimeout(function() {
        $('.content-toggle[data-content-id="'+contentId+'"]').css('opacity', 1);
      }, 500);

    });



    //HÉBERGEMENT


      $('.lightbox-toggle.chalet').on('click touch', function() {

        var current = $(this);

        setTimeout(function() {

          var slideId = current.attr('data-slider-id');

          $('.chalet-slider').css('display', 'none');

          $('.chalet-slider.'+slideId).fadeIn();

          $('.chalet-slider.'+slideId).addClass('visible');

          $('.chalet-slider.'+slideId).find('.chalet-slider-nav').addClass('visible');

          $('.chalet-slider.'+slideId).find('.chalet-slider-nav').slick({
            dots: false,
            arrows: true,
            slidesToShow: 6,
            lazyLoad: 'ondemand',
            focusOnSelect: true,
            responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 5,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
              }
            },
          ]

          });

        }, 500);       
    


       

      });

      $('.chalet-slider-nav').on('beforeChange', function(event, slick, currentSlide, nextSlide){
       
        var NextSlideDomImageUrl = $(slick.$slides.get(nextSlide)).attr('data-image-url');

        $(this).parent().parent().find('.big-image').css('background-image', 'url('+NextSlideDomImageUrl+')');

      });

      $('.chalet-slider-nav').on('init', function(event, slick, currentSlide, nextSlide){
       
         $('.chalet-slider-nav .slick-slide').on('click touch', function() {

          var NextSlideDomImageUrl = $(this).attr('data-image-url');

          $(this).parent().parent().parent().parent().find('.big-image').css('background-image', 'url('+NextSlideDomImageUrl+')');

          return false;

        });

        $(this).parent().parent().parent().parent().find('.big-image-wrapper .slick-prev').on('click touch', function() {
          
          $(this).parent().parent().find('.chalet-slider-nav').slick('slickNext');
        });
        $(this).parent().parent().parent().parent().find('.big-image-wrapper .slick-next').on('click touch', function() {
          $(this).parent().parent().find('.chalet-slider-nav').slick('slickNext');
        });

      });

      $('#chalet-filter li').on('click touch', function() {

        var currentId = $(this).attr('data-id');
        var activeFilters = $('#chalet-active-filters').val();

        activeFilters = activeFilters.split(",");

        activeFilters = activeFilters.filter(function(v){return v!==''});

        if($(this).hasClass('active')){
          //REMOVE
          $(this).removeClass('active');

          activeFilters.splice( $.inArray(currentId, activeFilters), 1 );

          activeFilters = activeFilters.join();          

          $('#chalet-active-filters').val(activeFilters);

          updateChaletsList();

        }else {
          //ADD
          $(this).addClass('active');
        
          activeFilters.push(parseInt(currentId));
          activeFilters = activeFilters.join();          

          $('#chalet-active-filters').val(activeFilters);

          updateChaletsList();
        }

        return false;


      });

    //FORFAITS

    $('.forfaits-list .forfait').on('click touch', function() {

      $(this).toggleClass('open');

    });


    //Ajustement scroll
    if(location.href.indexOf("#") != -1) {
      setTimeout(function() {
        $('html,body').animate({
            scrollTop: $(window).scrollTop() - 140
        });
      }, 500);      
    }


    //------- VIDEO LIGHTBOX -------
        // Vidéo trigger
        $('.videoTrigger').click(function() {
            //Si c'est un size chart
            if ($(this).hasClass('sizeChart')) {
                $('#video-lightbox').removeClass('hidden-lightbox');
                setTimeout(function() {
                    $('#video-lightbox').addClass('open');
                }, 500);
                var debutString = '<img src="';
                var idVideo = $(this).attr('data-size');
                var finString = '"/>';
                $('#video-lightbox .video').html(debutString + idVideo + finString);
                $('body').addClass('no-scroll');

                var ratio = 2.35;

                var largeur = $('#video-lightbox iframe').width();
                $('#video-lightbox iframe').height(largeur / ratio);
            } else {
                //Si c'est un vidéo
                $('#video-lightbox').removeClass('hidden-lightbox');
                setTimeout(function() {
                    $('#video-lightbox').addClass('open');
                }, 500);


                var idVideo = $(this).attr('data-id');

                if(idVideo.indexOf('youtube') >= 0){
                    var debutString = '<iframe class="youtube" width="80%" height="400px" src="';
                    var finString = '?autoplay=1&output=embed" frameborder="0" allowfullscreen></iframe>';
                }else{
                    var debutString = '<iframe class="vimeo" src="//player.vimeo.com/video/';
                    
                    if($(window).width() >= 1024) {
                      var finString = '?autoplay=1&showinfo=0&output=embed" width="80%" height="400px" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
                    }else {
                      var finString = '?autoplay=1&showinfo=0&muted=1&output=embed" width="80%" height="400px" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
                    }
                }

                $('#video-lightbox .video').html(debutString + idVideo + finString);
                $('body').addClass('no-scroll');


                if(idVideo.indexOf('youtube') >= 0){
                    var ratio = 1.78;
                }else{
                    var ratio = 2.35;
                }

                var largeur = $('#video-lightbox iframe').width();
                $('#video-lightbox iframe').height(largeur / ratio);
            }

        });


        //Close video lightbox, on click
        $('#video-lightbox').click(function() {
            if ($(this).hasClass('open')) {
                $('#video-lightbox').removeClass('open');
                setTimeout(function() {
                    $('#video-lightbox .video').html('');
                    $('body').removeClass('no-scroll');
                    $('#video-lightbox').addClass('hidden-lightbox');
                }, 600);
            }
        });
        //Close video lightbox, on click on close-button
        $('button.close-videoPanel').click(function() {
            $('#video-lightbox').removeClass('open');
            setTimeout(function() {
                $('#video-lightbox .video').html('');
                $('body').removeClass('no-scroll');
                $('#video-lightbox').addClass('hidden-lightbox');
            }, 600);

        });


  }

  function updateChaletsList(){

    $('#chalet-eloignes .no-result').fadeOut();

    var activeFilters = $('#chalet-active-filters').val();

    activeFilters = activeFilters.split(",");

    activeFilters = activeFilters.filter(function(v){return v!==''});

    $('.chalets-list.eloigne').css('opacity', '0');

    if(activeFilters.length == 0){

       setTimeout(function() {

        $('.chalets-list.eloigne .chalet').each(function() {

          $(this).slideDown(200);

        });

        setTimeout(function() {

          $('.chalets-list.eloigne').css('opacity', '1');

        }, 500);


      }, 500);

    }else {

    setTimeout(function() {

      var i;
      for (i = 0; i < activeFilters.length; i++) {
        
        activeFilters[i] = parseInt(activeFilters[i]);

      }

      activeFilters.sort(function(a, b) {
        return a - b;
      });

      //COMPARE DES CATÉGORIES SÉLECTIONNÉ VS ACTIVE DU CHALET
      $('.chalets-list.eloigne .chalet').each(function() {

        var chaletId = $(this).attr('cat-id');
        var visible = false;

        activeFilters = activeFilters.toString();

        if(chaletId == activeFilters){
          visible = true;
        }else {

          activeFilters =  activeFilters.split(",");
          chaletId =  chaletId.split(",");

          if(activeFilters.length < chaletId.length){

            var inArray = arrayContainsAnotherArray(activeFilters, chaletId);

            if(inArray == true){
              visible = true;
            }

          }
        }

        if(visible == true){
          $(this).slideDown(200);
        }else {
          $(this).slideUp(200);
        }

      });

      setTimeout(function() {

        $('.chalets-list.eloigne').css('opacity', '1');
        countActive();
      }, 500);


    }, 500);

    }

  }

  function countActive() {

    var allHidden = true;

     $('.chalets-list.eloigne .chalet').each(function() {

      if($(this).is(":visible")){
        allHidden = false;
      }

     });

     if(allHidden == false){
      $('#chalet-eloignes .no-result').fadeOut();
     }else {
      $('#chalet-eloignes .no-result').fadeIn();
     }

  }

  function arrayContainsAnotherArray(needle, haystack){
    for(var i = 0; i < needle.length; i++){
      if(haystack.indexOf(needle[i]) === -1)
         return false;
    }
    return true;
  }

  window.onload = configurer;

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
